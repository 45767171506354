<template>
  <div
    id="app"
    :nav-opened="navOpened"
    :search-opened="searchOpened"
    :show-nav="showNav"
    v-if="settings.maintenance === false"
    :page="page"
    :announcement="settings.announcement && settings.announcement.active"
    :no-touch="!touch"
  >
    <header>
      <div class="wrapper">
        <a class="announcement-bar" :href="settings.announcement.url" target="_blank" v-if="settings.announcement && settings.announcement.active">
          {{ settings.announcement.content }}
          <i class="ic ic-arrow-right-w"></i>
        </a>
        <nav v-if="isMobile">
          <a @click="navOpened = true"><Icon icon="bars" type="fas" /></a>
          <router-link class="logo" to="/">
            <img src="@/assets/images/wb_logo.svg" alt="WestBIC">
          </router-link>
        </nav>
        <nav v-else>
          <router-link class="logo" to="/">
            <img src="@/assets/images/wb_logo.svg" alt="WestBIC">
          </router-link>
          <router-link to="/">Home</router-link>
          <Dropdown
            behavior="menu"
            :options="[
              ...wwdOptions,
              ...links.filter(l => l.category === 'what-we-do').map(l => ({
                label: l.title,
                value: `/${l.seo.slug}`,
              })),
            ]"
            @change="changeRoute"
          >
            <router-link to="/what-we-do">
              What We Do
              <Icon icon="chevron-down" size="small" type="fas" />
            </router-link>
          </Dropdown>
          <router-link class="about-link" to="/case-studies" v-if="!links.filter(l => l.category === 'case-studies').length">Our Clients</router-link>
          <Dropdown
            v-else
            behavior="menu"
            class="about-link"
            :options="[
              ...links.filter(l => l.category === 'case-studies').map(l => ({
                label: l.title,
                value: `/${l.seo.slug}`,
              })),
            ]"
            @change="changeRoute"
          >
            <router-link to="/case-studies">
              Our Clients
              <Icon icon="chevron-down" size="small" type="fas" />
            </router-link>
          </Dropdown>
          <Dropdown
            behavior="menu"
            :options="[
              {
                label: 'In The News',
                value: '/news',
              },
              {
                label: 'Events',
                value: '/events',
              },
              {
                label: 'Podcast',
                value: '/podcast',
              },
              ...links.filter(l => l.category === 'news').map(l => ({
                label: l.title,
                value: `/${l.seo.slug}`,
              })),
            ]"
            @change="changeRoute"
          >
            <router-link to="/news">
              News & Events
              <Icon icon="chevron-down" size="small" type="fas" />
            </router-link>
          </Dropdown>
          <Dropdown
            behavior="menu"
            :options="[
              {
                label: 'Collaborative Projects',
                value: '/initiatives',
              },
              {
                label: 'Incubation',
                value: '/incubation',
              },
              {
                label: 'Partners',
                value: '/partners-network',
              },
              {
                label: 'Regional Coworking Hubs',
                value: '/regional-coworking-hubs',
              },
              ...links.filter(l => l.category === 'initiatives').map(l => ({
                label: l.title,
                value: `/${l.seo.slug}`,
              })),
            ]"
            @change="changeRoute"
          >
            <router-link to="/initiatives">
              Collaborative Projects
              <Icon icon="chevron-down" size="small" type="fas" />
            </router-link>
          </Dropdown>
          <Dropdown
            class="about-menu"
            behavior="menu"
            :options="[
              {
                label: 'About Us',
                value: '/about-us',
              },
              {
                label: 'Contact',
                value: '/contact',
              },
            ]"
            @change="changeRoute"
          >
            <router-link to="/about-us">
              About Us
              <Icon icon="chevron-down" size="small" type="fas" />
            </router-link>
          </Dropdown>
        </nav>
        <div class="contact-info">
          <a @click="searchOpened = true">
            <Icon icon="search" type="fas" />
          </a>
          <div class="socials" v-if="settings.active">
            <a v-if="settings.active.facebook" :href="`https://fb.com/${settings.facebook}`" target="_blank" class="social">
              <Icon icon="facebook-f" />
            </a>
            <a v-if="settings.active.twitter" :href="`https://twitter.com/${settings.twitter}`" target="_blank" class="social">
              <Icon icon="twitter" />
            </a>
            <a v-if="settings.active.linkedin" :href="`https://www.linkedin.com/company/${settings.linkedin}/`" target="_blank" class="social">
              <Icon icon="linkedin-in" />
            </a>
            <a v-if="settings.active.instagram && settings.instagram" :href="`https://instagram.com/${settings.instagram}`" target="_blank" class="social">
              <Icon icon="instagram" />
            </a>
            <a v-if="settings.active.youtube && settings.youtube" :href="`${settings.youtube}`" target="_blank" class="social">
              <Icon icon="youtube" />
            </a>
            <a v-if="settings.active.vimeo && settings.vimeo" :href="`https://vimeo.com/${settings.vimeo}`" target="_blank" class="social">
              <Icon icon="vimeo-v" />
            </a>
          </div>
          <router-link to="/contact" class="btn ghost">Contact</router-link>
        </div>
      </div>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <ContactForm v-if="$route && $route.path !== '/contact'" />
    <section class="bottom-note" v-if="settings && settings.note">
      <div class="wrapper small">
        <router-link v-if="settings.note.link.startsWith('/')" :to="settings.note.link" class="note">
          <div class="note-content">
            <h4>{{settings.note.title}}</h4>
            <p>{{settings.note.description}}</p>
          </div>
          <img src="@/assets/images/wb_note_btn.svg" alt="">
        </router-link>
        <a v-else :href="settings.note.link" target="_blank" rel="noopener" class="note">
          <div class="note-content">
            <h4>{{settings.note.title}}</h4>
            <p>{{settings.note.description}}</p>
          </div>
          <img src="@/assets/images/wb_note_btn.svg" alt="">
        </a>
      </div>
    </section>
    <footer>
      <div class="wrapper mini">
        <nav class="nav-section">
          <div class="col">
            <router-link to="/">Home</router-link>
          </div>
          <div class="col">
            <router-link to="/" class="mobile">Home</router-link>
            <router-link to="/what-we-do">What we do</router-link>
            <router-link to="/about-us">About us</router-link>
            <router-link to="/bic-network">BIC network</router-link>
            <router-link to="/our-team">Our team</router-link>
            <router-link v-for="link in links.filter(l => l.category === 'what-we-do')" :key="link.id" :to="`/${link.seo.slug}`">{{link.title}}</router-link>
          </div>
          <div class="col">
            <router-link to="/news">In the news</router-link>
            <router-link to="/events">Events</router-link>
            <router-link to="/podcast">Podcast</router-link>
            <router-link v-for="link in links.filter(l => l.category === 'news')" :key="link.id" :to="`/${link.seo.slug}`">{{link.title}}</router-link>
          </div>
          <div class="col">
            <router-link to="/case-studies">Our clients</router-link>
            <router-link to="/initiatives">Collaborative projects</router-link>
            <router-link to="/incubation">Incubation</router-link>
            <router-link to="/partners-network">Partners</router-link>
            <router-link to="/regional-coworking-hubs">Regional Coworking Hubs</router-link>
            <router-link v-for="link in links.filter(l => l.category === 'initiatives' || l.category === 'case-studies')" :key="link.id" :to="`/${link.seo.slug}`">{{link.title}}</router-link>
          </div>
          <div class="col">
            <router-link to="/contact">Contact</router-link>
            <router-link v-for="link in links.filter(l => !l.category)" :key="link.id" :to="`/${link.seo.slug}`">{{link.title}}</router-link>
            <div v-if="settings.active.facebook || settings.active.twitter || (settings.instagram && settings.active.instagram) || settings.active.linkedin || (settings.active.vimeo && settings.vimeo) || (settings.active.youtube && settings.youtube)">
              <div class="socials" v-if="settings.active">
                <a v-if="settings.active.facebook" :href="`https://fb.com/${settings.facebook}`" target="_blank" class="social">
                  <Icon icon="facebook-f" size="small" rounded />
                </a>
                <a v-if="settings.active.twitter" :href="`https://twitter.com/${settings.twitter}`" target="_blank" class="social">
                  <Icon icon="twitter" size="small" rounded />
                </a>
                <a v-if="settings.active.linkedin" :href="`https://www.linkedin.com/company/${settings.linkedin}/`" target="_blank" class="social">
                  <Icon icon="linkedin-in" size="small" rounded />
                </a>
                <a v-if="settings.active.instagram && settings.instagram" :href="`https://instagram.com/${settings.instagram}`" target="_blank" class="social">
                  <Icon icon="instagram" size="small" rounded />
                </a>
                <a v-if="settings.active.youtube && settings.youtube" :href="`${settings.youtube}`" target="_blank" class="social">
                  <Icon icon="youtube" size="small" rounded />
                </a>
                <a v-if="settings.active.vimeo && settings.vimeo" :href="`https://vimeo.com/${settings.vimeo}`" target="_blank" class="social">
                  <Icon icon="vimeo-v" size="small" rounded />
                </a>
              </div>
            </div>
          </div>
          <router-link class="logo" to="/">
            <img src="@/assets/images/wb_logo-w.svg" alt="WestBIC">
          </router-link>
        </nav>
        <div class="nav-section vertical sub-section" v-if="settings.active.csc && settings.csc">
          <div class="nav-section-content">
            <h5>Customer Service Charter</h5>
            <p>You can download our customer service charter here</p>
          </div>
          <a :href="settings.csc" target="_blank" class="btn ghost white round">Download now</a>
        </div>
        <div class="nav-section vertical">
          <div class="nav-footer">
            Website design by
            <a
              href="https://blackpugstudio.com"
              alt="Black Pug Studio | Creative Website Agency | Galway, Ireland"
              target="_blank"
              rel="noopener"
            >Black Pug Studio</a>.
          </div>
          <div class="ebn-info">
            <a href="https://ebn.eu" target="_blank" rel="noopener">
              <img src="@/assets/images/euBIC_logo.svg" class="partner-logo" alt="EU|BIC">
            </a>
            <div>WestBIC is a <a href="https://ebn.eu" target="_blank" rel="noopener">certified EU | BIC</a> member.</div>
          </div>
        </div>
        <div class="nav-section vertical dashed">
          <div class="copyright">
            Copyright © {{ currentYear }} WestBIC. All rights reserved
          </div>
          <div class="privacy-policy">
            <a v-if="settings.active.dpp && settings.dpp" :href="settings.dpp" rel="noopener" target="_blank">
              Data Protection Policy
            </a>
            <a v-if="settings.active.cp && settings.cp" :href="settings.cp" rel="noopener" target="_blank">
              Cookie Policy
            </a>
            <router-link to="/sitemap">Site Map</router-link>
          </div>
          <div>Galway, Ireland</div>
        </div>
        <div class="privacy-policy">
          <a v-if="settings.active.dpp && settings.dpp" :href="settings.dpp" rel="noopener" target="_blank">
            Data Protection Policy
          </a>
          <a v-if="settings.active.cp && settings.cp" :href="settings.cp" rel="noopener" target="_blank">
            Cookie Policy
          </a>
          <router-link to="/sitemap">Site Map</router-link>
        </div>
      </div>
    </footer>
    <nav class="mobile-nav" v-if="isMobile">
      <div class="nav-header">
        <router-link class="logo" to="/" @click.native="navOpened = false">
          <img src="@/assets/images/wb_logo.svg" alt="WestBIC">
        </router-link>
        <a @click="navOpened = false"><Icon icon="times" type="fas" /></a>
      </div>
      <div class="nav-links">
        <router-link to="/" @click.native="navOpened = false">Home</router-link>
        <router-link to="/what-we-do" @click.native="navOpened = false">What we do</router-link>
        <router-link to="/case-studies" @click.native="navOpened = false">Our clients</router-link>
        <router-link to="/news" @click.native="navOpened = false">In the news</router-link>
        <router-link to="/events" @click.native="navOpened = false">Events</router-link>
        <router-link to="/podcast" @click.native="navOpened = false">Podcast</router-link>
        <router-link to="/initiatives" @click.native="navOpened = false">Collaborative projects</router-link>
        <router-link to="/incubation" @click.native="navOpened = false">Incubation</router-link>
        <router-link to="/partners-network" @click.native="navOpened = false">Partners</router-link>
        <router-link to="/regional-coworking-hubs" @click.native="navOpened = false">Regional Coworking Hubs</router-link>
        <router-link to="/bic-network" @click.native="navOpened = false">BIC network</router-link>
        <router-link to="/about-us" @click.native="navOpened = false">About us</router-link>
        <router-link to="/contact" @click.native="navOpened = false">Contact</router-link>
        <router-link v-for="link in links" :key="link.id" :to="`/${link.seo.slug}`">{{link.title}}</router-link>
      </div>
      <div class="socials" v-if="settings.active">
        <a v-if="settings.active.facebook" :href="`https://fb.com/${settings.facebook}`" target="_blank" class="social">
          <Icon icon="facebook-f" size="small" rounded />
        </a>
        <a v-if="settings.active.twitter" :href="`https://twitter.com/${settings.twitter}`" target="_blank" class="social">
          <Icon icon="twitter" size="small" rounded />
        </a>
        <a v-if="settings.active.linkedin" :href="`https://www.linkedin.com/company/${settings.linkedin}/`" target="_blank" class="social">
          <Icon icon="linkedin-in" size="small" rounded />
        </a>
        <a v-if="settings.active.instagram && settings.instagram" :href="`https://instagram.com/${settings.instagram}`" target="_blank" class="social">
          <Icon icon="instagram" size="small" rounded />
        </a>
        <a v-if="settings.active.youtube && settings.youtube" :href="`${settings.youtube}`" target="_blank" class="social">
          <Icon icon="youtube" size="small" rounded />
        </a>
        <a v-if="settings.active.vimeo && settings.vimeo" :href="`https://vimeo.com/${settings.vimeo}`" target="_blank" class="social">
          <Icon icon="vimeo-v" size="small" rounded />
        </a>
      </div>
    </nav>
    <div class="cookie-settings" :opened="cookieSettings.allowed === null">
      <div class="cookie-title">Manage Cookie Consent</div>
      <div class="cookie-text">To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behaviour or unique IDs on this site. Not consenting or withdrawing consent, may adversely affect certain features and functions. <span v-if="settings.active.cp && settings.cp">Read our <a :href="settings.cp" target="_blank" rel="noopener">Cookie Policy</a> for more information.</span></div>
      <div class="cookie-box">
        <div class="cookie-box-title">
          <div>Functional</div>
          <div class="cookie-box-action">
            <span>Always active</span>
          </div>
        </div>
        <div class="cookie-box-text">The technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a specific service explicitly requested by the subscriber or user, or for the sole purpose of carrying out the transmission of a communication over an electronic communications network. Our function cookies include: Google Fonts, FontAwesome and Medium.</div>
      </div>
      <div class="cookie-box">
        <div class="cookie-box-title">
          <div>Statistics</div>
          <div class="cookie-box-action">
            <bps-toggle-button
              v-model="cookieSettings.analytics"
            ></bps-toggle-button>
          </div>
        </div>
        <div class="cookie-box-text">The technical storage or access that is used exclusively for anonymous statistical purposes. Without a subpoena, voluntary compliance on the part of your Internet Service Provider, or additional records from a third party, information stored or retrieved for this purpose alone cannot usually be used to identify you. This website uses Google Analytics to measure activity across all pages.</div>
      </div>
      <div class="cookie-actions">
        <button class="btn medium" @click="setCookieSettings(true, true)">Accept</button>
        <button class="btn medium ghost" @click="setCookieSettings(false, false)">Deny</button>
        <button class="btn medium ghost" @click="setCookieSettings(true, cookieSettings.analytics)">Save preferences</button>
      </div>
    </div>
    <div class="search-panel" @click="searchOpened = false">
      <div class="search-container" @click.stop>
        <div class="search-bar">
          <form @submit.prevent="submitSearch">
            <span class="search-input">
              <input
                placeholder="Search..."
                type="text"
                maxlength="500"
                v-model="searchTerms"
                :disabled="searching"
                required
              />
              <div class="loader" v-if="searching"></div>
              <button
                type="button"
                v-else-if="searchTerms"
                class="ic ic-close-b"
                @click="searchTerms = ''"
              ></button>
            </span>
            <button class="btn" type="submit" :disabled="searching">
              Search
            </button>
          </form>
          <button class="ic ic-close" @click="searchOpened = false"></button>
        </div>
        <div class="search-results" v-if="searchResults">
          <div class="results-list" v-if="searchResults.results.length">
            <h5>Search results for "{{ searchResults.terms }}".</h5>
            <div
              class="results-category"
              v-for="category in [
                'cases',
                'events',
                'incubators',
                'initiatives',
                'offices',
                'team',
                'pages',
              ].filter((c) => searchResults[c].length)"
              :key="`results-${category}`"
            >
              <h6>{{ category }}</h6>
              <div
                class="result"
                v-for="(result, i) in searchResults[category]"
                :key="`search-result-${i}`"
              >
                <div class="result-title">
                  <router-link :to="result.link">{{
                    result.title
                  }}</router-link>
                </div>
                <div class="result-description">{{ result.description }}</div>
              </div>
            </div>
          </div>
          <div class="no-results" v-else>
            No results found for "{{ searchResults.terms }}".
          </div>
        </div>
      </div>
    </div>
    <div
      class="admin-bar"
      v-if="env === 'staging' && admin && admin.level === 0"
      :test="admin.test_mode"
    >
      <span>Test mode</span>
      <bps-toggle-button
        v-model="admin.test_mode"
        @input="setTest"
      ></bps-toggle-button>
    </div>
    <div class="overlay" :opened="overlayOpened">
      <div class="dialog" v-if="settings.popup && settings.popup.active">
        <img class="dialog-image" :src="settings.popup.image" :alt="settings.popup.title">
        <div class="dialog-container">
          <div class="dialog-title">{{ settings.popup.title }}</div>
          <div class="dialog-content" v-html="settings.popup.content.replace(/\n/g, '<br>')"></div>
          <div class="dialog-actions">
            <button class="btn ghost" @click="closePopup">Close</button>
            <a class="btn" :href="settings.popup.url" target="_blank">Visit <i class="ic ic-link-w"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="podcast-player" v-if="podcast" :minimized="podcast.minimized">
      <div
        class="podcast-thumbnail"
        :style="
          podcast.current.cover && {
            backgroundImage: `url(${podcast.current.cover}`,
          }
        "
      >
        <button class="ic ic-close-w" @click="podcast = null"></button>
        <button
          :class="`ic ic-${podcast.minimized ? 'maximize' : 'minimize'}`"
          @click="podcast.minimized = !podcast.minimized"
        ></button>
        <button
          class="ic ic-skip-previous"
          @click="playEpisode(podcast.index - 1)"
          :disabled="podcast.index <= 0"
        ></button>
        <button
          class="ic ic-pause"
          v-if="podcast.playing"
          @click="$refs.podcastPlayer.pause()"
        ></button>
        <button
          class="ic ic-play-btn"
          v-else
          @click="$refs.podcastPlayer.play()"
        ></button>
        <button
          class="ic ic-skip-next"
          @click="playEpisode(podcast.index + 1)"
          :disabled="podcast.index >= podcast.episodes.length - 1"
        ></button>
        <div class="progress" @click="setPlayerTime">
          <div class="bar" :style="{ width: `${podcast.progress}%` }"></div>
        </div>
      </div>
      <div class="podcast-title">{{ podcast.current.title }}</div>
      <audio
        ref="podcastPlayer"
        :src="podcast.current.enclosure.url"
        @play="setPlaying(true)"
        @pause="setPlaying(false)"
        @timeupdate="updateTime($refs.podcastPlayer)"
        @canplay="$event.target.play()"
      />
    </div>
  </div>
  <div v-else-if="settings.maintenance === true">
    <h1 class="maintenance">
      <img alt="WestBIC" src="@/assets/images/wb_logo.svg" />
      The website is under maintenance.<br />Please come back later.
    </h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./components/Icon";
import Dropdown from "./components/Dropdown";
import ContactForm from "./components/ContactForm";
import BpsToggleButton from "./components/BpsToggleButton";

export default {
  name: "app",
  data() {
    return {
      navOpened: false,
      searchOpened: false,
      seo: null,
      links: [],
      currentYear: new Date().getFullYear(),
      width: window.innerWidth,
      newsletterEmail: "",
      searchTerms: "",
      touch: "ontouchstart" in document.documentElement,
      searchResults: null,
      subscribed: false,
      searching: false,
      loading: false,
      navBackground: false,
      error: null,
      showNav: true,
      podcast: null,
      overlayOpened: false,
      pages: {
        "our-team": "Our Team",
        "board-of-directors": "Board of Directors",
        "regional-offices": "Regional Offices",
        "case-studies": "Our clients",
        "what-we-do": "What<br>We Do",
        podcast: "Podcast",
      },
      cookieSettings: window.localStorage.getItem('cookie-settings')
        ? JSON.parse(window.localStorage.getItem('cookie-settings'))
        : {
          allowed: 'loading',
          analytics: true,
        },
    };
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
      functions: "getFunctions",
      isMobile: "getMobile",
      testMode: "getTest",
      admin: "getAdmin",
      env: "getEnv",
      screenWidth: "getScreenWidth",
    }),
    isIndex() {
      return this.$route.path === "/";
    },
    page() {
      return this.$route.path.split("/")[1] || "home";
    },
    slug() {
      return this.$route.path.split("/")[2] || null;
    },
    db() {
      return this.$store.state.firebase.firestore;
    },
    wwdOptions() {
      const options = [
        {
          label: 'What We Do',
          value: '/what-we-do',
        },
        {
          label: 'About Us',
          value: '/about-us',
        },
        {
          label: 'BIC Network',
          value: '/bic-network',
        },
        {
          label: 'Our Team',
          value: '/our-team',
        },
      ];
      if (this.screenWidth <= 1180) {
        options.push({
          label: 'Our Clients',
          value: '/case-studies',
        });
      }
      return options;
    },
  },
  components: {
    Icon,
    Dropdown,
    ContactForm,
    BpsToggleButton,
  },
  watch: {
    $route() {
      this.navOpened = this.searchOpened = false;
      this.setPageTitle();
    },
    seo() {
      this.setPageTitle();
    },
    testMode() {
      this.getItems();
    },
    settings() {
      if (this.settings.popup) {
        this.overlayOpened = this.settings.popup.active;
      }
    },
  },
  methods: {
    setTest(test_mode) {
      this.db().collection("admins").doc(this.admin.id).update({ test_mode });
    },
    setPageTitle() {
      if (this.seo && !["event", "incubation"].includes(this.$route.name)) {
        document.title = this.seo.title;
      }
    },
    closePopup() {
      this.overlayOpened = false;
    },
    initGA() {
      if (!window.location.href.includes('westbic.ie')) {
        return;
      }
      
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      
      window.ga('create', 'UA-157554711-1', 'auto');
      window.ga('set', 'page', window.location.pathname);
      window.ga('send', 'pageview');

      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-663746536';
      script.async = 1;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function(){ window.dataLayer.push(arguments); };
      window.gtag('js', new Date());
      window.gtag('config', 'AW-663746536');
      window.gtag('event', 'conversion', {
        'send_to': 'AW-663746536/ozvSCL2LprEDEOjvv7wC',
        event_callback: function() {
          // eslint-disable-next-line no-console
          console.log('GTAG INIT');
        },
      });
    },
    setCookieSettings(allowed, analytics) {
      this.cookieSettings = { allowed, analytics };
      window.localStorage.setItem('cookie-settings', JSON.stringify(this.cookieSettings));
      if (analytics) {
        this.initGA();
      }
    },
    getItems() {
      if (this.linksRef) this.linksRef();
      this.linksRef = this.db()
        .collection(`${this.testMode ? "" : ""}custom_pages`)
        .where('listed', '==', true)
        .onSnapshot((links) => {
          this.links = links.docs.map((link) => link.data());
        });
    },
    subscribeNewsletter() {
      if (this.loading) return false;
      this.error = null;
      this.loading = true;
      clearTimeout(this.newsletterTO);
      this.functions
        .httpsCallable("subscribeNewsletter")({
          email: this.newsletterEmail,
          test: this.testMode,
        })
        .then(() => {
          this.newsletterEmail = "";
          this.loading = false;
          this.subscribed = true;
          this.newsletterTO = setTimeout(() => (this.subscribed = false), 3000);
        })
        .catch((err) => {
          this.loading = false;
          this.error = err.message;
          this.newsletterTO = setTimeout(() => (this.error = null), 3000);
        });
    },
    playEpisode(index) {
      this.podcast.index = index;
      this.podcast.current = this.podcast.episodes[index];
    },
    setPlaying(playing) {
      if (this.podcast) this.podcast.playing = playing;
    },
    updateTime(player) {
      if (!player) return;
      this.podcast.progress = (player.currentTime / player.duration) * 100;
    },
    setPlayerTime(e) {
      const player = this.$refs.podcastPlayer;
      if (!player) return;
      player.currentTime =
        (e.offsetX / e.currentTarget.clientWidth) * player.duration;
    },
    submitSearch() {
      if (this.searching || this.searchTerms.length < 3) return false;
      this.searchResults = null;
      this.searching = true;
      this.functions
        .httpsCallable("searchTerms")({
          terms: this.searchTerms,
          test: this.testMode,
        })
        .then((res) => {
          this.searchResults = res.data;
          this.searching = false;
        })
        .catch(() => {
          this.searchResults = [];
          this.searching = false;
        });
    },
    getTweets() {
      clearTimeout(this.tweetsTO);
      this.functions
        .httpsCallable("getTweets")({ test: this.testMode })
        .then(() => {
          this.tweetsTO = setTimeout(() => this.getTweets(), 30000);
        })
        .catch(() => {
          this.tweetsTO = setTimeout(() => this.getTweets(), 30000);
        });
    },
    getSEO() {
      if (this.seoRef) this.seoRef();
      this.seoRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}settings`)
        .doc("seo")
        .onSnapshot((seo) => {
          this.seo = seo.exists
            ? seo.data()
            : {
                title: "",
                description: "",
                keywords: "",
                image: "",
              };
        });
    },
    changeRoute(option) {
      this.$router.push(option.value);
    },
  },
  mounted: function () {
    this.getSEO();
    this.getTweets();
    this.getItems();
    window.addEventListener(
      "resize",
      () => (this.width = window.innerWidth),
      false
    );
    window.addEventListener("subscribeNewsletter", () => {
      this.navOpened = true;
      this.newsletterEmail = "";
      this.$nextTick(() => {
        this.$refs.newsletterButton.click();
        this.$refs.newsletterInput.focus();
      });
    });
    window.addEventListener("playPodcast", (e) => {
      this.podcast = null;
      this.$nextTick(() => {
        this.podcast = {
          ...e.detail,
          playing: false,
          progress: 0,
          minimized: false,
        };
      });
    });
    window.addEventListener("scroll", () => {
      const scroll =
        window.scrollY || document.documentElement.scrollTop || 0;
      this.showNav =
        scroll <= 64 || (this.currentScroll && this.currentScroll > scroll);
      this.navBackground = this.showNav && scroll > 0;
      this.currentScroll = scroll;
    });
    if (this.cookieSettings.allowed === 'loading') {
      setTimeout(() => {
        this.cookieSettings = {
          ...this.cookieSettings,
          allowed: null,
        };
      }, 5000);
    } else if (this.cookieSettings.allowed && this.cookieSettings.analytics) {
      this.initGA();
    }
  },
};
</script>

<style>
@font-face {
  font-family: 'Metropolis';
  src: url('./assets/fonts/Metropolis-Black.eot');
  src: url('./assets/fonts/Metropolis-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Metropolis-Black.woff2') format('woff2'),
      url('./assets/fonts/Metropolis-Black.woff') format('woff'),
      url('./assets/fonts/Metropolis-Black.ttf') format('truetype'),
      url('./assets/fonts/Metropolis-Black.svg#Metropolis-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

#app {
  min-height: 100%;
}

#app[announcement] {
  padding-top: 32px;
}

/* #app[nav-opened] main {
  height: 100vh;
  overflow: hidden;
} */

button,
a {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

address {
  font-style: normal;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: bold 22px/1 "Metropolis";
  font-size: inherit;
  margin: 0;
}

h3 {
  font-size: 80px;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 18px;
}

.admin-bar {
  position: fixed;
  width: 180px;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px 0;
  margin: auto;
  background: #eaeaea;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 9999;
  transition: all 0.16s;
}

.admin-bar[test] {
  background: #ff6666;
  color: #fff;
}

.admin-bar .bps-toggle-button-container {
  margin-left: 16px;
}

input,
textarea,
select {
  display: block;
  width: 100%;
  height: 48px;
  border: none;
  background: rgba(101, 102, 98, .06);
  border-radius: 12px;
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: #343434;
  padding: 0 12px;
}

select {
  background: rgba(101, 102, 98, .06) url(./assets/images/wb_ic_chevron-down.svg) no-repeat 97% center/16px auto;
}

textarea {
  height: 192px;
  resize: none;
  padding: 8px 12px;
}

.field {
  margin-bottom: 24px;
}

.field label {
  display: block;
  margin-bottom: 12px;
}

.buttons {
  margin-top: 32px;
}

.buttons .btn:not(:last-child) {
  margin-right: 16px;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background: #1dacd6;
  font: 600 18px/1 "Inter";
  height: 58px;
  min-width: 144px;
  border-radius: 12px;
  color: #fff;
  padding: 0 32px;
  margin: 0;
  border: none;
  text-align: center;
}

.btn.medium {
  height: 52px;
}

.btn.small {
  height: 48px;
  font-size: 16px;
  padding: 0 20px;
}

.btn.red {
  background: #DB735C;
}

.btn.sea {
  background: #0A7E8C;
}

.btn.ghost {
  background: none;
  border: 1px solid;
  color: #1dacd6;
}

#app[no-touch] .btn {
  transition: all .2s ease-out;
}

.btn.ghost.white {
  color: #fff;
}

.btn.ghost.red {
  color: #DB735C;
}

#app[no-touch] .btn:hover {
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
}

#app[no-touch] .btn.ghost:hover {
  background: #1dacd6;
  color: #fff;
}

#app[no-touch] .btn.ghost.red:hover {
  background: #DB735C;
  color: #fff;
}

#app[no-touch] .btn.ghost.white:hover {
  background: #fff;
  color: #343434;
}

.btn.icon {
  width: 58px;
  padding: 0;
  min-width: 0;
}

.btn.medium.icon {
  width: 52px;
}

.ic {
  display: inline-block;
  width: 2.22vh;
  height: 2.22vh;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  border: none;
  font: inherit;
  color: inherit;
}

.btn.placeholder,
.ic.placeholder {
  border-radius: 4px;
  background: #eee;
}

.ic.ic-menu {
  background-image: url(./assets/images/wb_ic_menu.svg);
}

.ic.ic-file {
  background-image: url(./assets/images/wb_ic_file.svg);
}

.ic.ic-chevron-l {
  background-image: url(./assets/images/wb_ic_chevron-l.svg);
}

.ic.ic-to-top {
  background-image: url(./assets/images/wb_ic_to-top.svg);
}

.ic.ic-plus {
  background-image: url(./assets/images/wb_ic_plus.svg);
}

.ic.ic-dialog {
  background-image: url(./assets/images/wb_ic_dialog.svg);
}

.ic.ic-info {
  background-image: url(./assets/images/wb_ic_info.svg);
}

.ic.ic-menu-w {
  background-image: url(./assets/images/wb_ic_menu-w.svg);
}

.ic.ic-spotify {
  background-image: url(./assets/images/wb_ic_spotify.svg);
}

.ic.ic-itunes {
  background-image: url(./assets/images/wb_ic_itunes.svg);
}

.ic.ic-arrow-r {
  background-image: url(./assets/images/wb_ic_arrow-r.svg);
}

.ic.ic-play {
  background-image: url(./assets/images/wb_ic_play.svg);
}

.ic.ic-play-btn {
  background-image: url(./assets/images/wb_ic_play-btn.svg);
}

.ic.ic-skip-previous {
  background-image: url(./assets/images/wb_ic_skip-previous.svg);
}

.ic.ic-skip-next {
  background-image: url(./assets/images/wb_ic_skip-previous.svg);
  transform: rotateZ(180deg);
}

.ic.ic-listen {
  background-image: url(./assets/images/wb_ic_listen.svg);
}

.ic.ic-maximize {
  background-image: url(./assets/images/wb_ic_maximize.svg);
}

.ic.ic-minimize {
  background-image: url(./assets/images/wb_ic_minimize.svg);
}

.ic.ic-pause {
  background-image: url(./assets/images/wb_ic_pause.svg);
}

.ic.ic-soundcloud {
  background-image: url(./assets/images/wb_ic_soundcloud.svg);
}

.ic.ic-rss {
  background-image: url(./assets/images/wb_ic_rss.svg);
}

.ic.ic-facebook-r {
  background-image: url(./assets/images/wb_ic_facebook-r.svg);
}

.ic.ic-twitter-r {
  background-image: url(./assets/images/wb_ic_twitter-r.svg);
}

.ic.ic-linkedin-r {
  background-image: url(./assets/images/wb_ic_linkedin-r.svg);
}

.ic.ic-mail-r {
  background-image: url(./assets/images/wb_ic_newsletter-r.svg);
}

.ic.ic-mail-rb {
  background-image: url(./assets/images/wb_ic_newsletter-rb.svg);
}

.ic.ic-twitter-rb {
  background-image: url(./assets/images/wb_ic_twitter-rb.svg);
}

.ic.ic-facebook-rb {
  background-image: url(./assets/images/wb_ic_facebook-rb.svg);
}

.ic.ic-linkedin-rb {
  background-image: url(./assets/images/wb_ic_linkedin-rb.svg);
}

.ic.ic-link-rb {
  background-image: url(./assets/images/wb_ic_link-rb.svg);
}

.ic.ic-link-r {
  background-image: url(./assets/images/wb_ic_link-r.svg);
}

.ic.ic-arrow-right {
  background-image: url(./assets/images/wb_ic_arrow-right.svg);
}

.ic.ic-arrow-right-w {
  background-image: url(./assets/images/wb_ic_arrow-right-w.svg);
}

.ic.ic-close {
  background-image: url(./assets/images/wb_ic_close.svg);
}

.ic.ic-close-w {
  background-image: url(./assets/images/wb_ic_close-w.svg);
}

.ic.ic-close-b {
  background-image: url(./assets/images/wb_ic_close-b.svg);
}

.ic.ic-search {
  background-image: url(./assets/images/wb_ic_search.svg);
}

.ic.ic-next {
  background-image: url(./assets/images/wb_ic_next.svg);
}

.ic.ic-search-w {
  background-image: url(./assets/images/wb_ic_search-w.svg);
}

.ic.ic-pin {
  background-image: url(./assets/images/wb_ic_pin.svg);
}

.ic.ic-link-w {
  background-image: url(./assets/images/wb_ic_link-w.svg);
}

.ic.ic-link-b {
  background-image: url(./assets/images/wb_ic_link-b.svg);
}

.ic.ic-eye {
  background-image: url(./assets/images/wb_ic_eye.svg);
}

.ic.ic-phone {
  background-image: url(./assets/images/wb_ic_phone.svg);
}

.ic.ic-mail {
  background-image: url(./assets/images/wb_ic_mail.svg);
}

.ic.ic-mail-b {
  background-image: url(./assets/images/wb_ic_mail-b.svg);
}

.ic.ic-instagram-w {
  background-image: url(./assets/images/wb_ic_instagram-w.svg);
}

.ic.ic-instagram {
  background-image: url(./assets/images/wb_ic_instagram.svg);
}

.ic.ic-youtube-w {
  background-image: url(./assets/images/wb_ic_youtube-w.svg);
}

.ic.ic-youtube {
  background-image: url(./assets/images/wb_ic_youtube.svg);
}

.ic.ic-yt {
  background-image: url(./assets/images/wb_ic_yt.svg);
}

.ic.ic-vimeo-w {
  background-image: url(./assets/images/wb_ic_vimeo-w.svg);
}

.ic.ic-vimeo {
  background-image: url(./assets/images/wb_ic_vimeo.svg);
}

.ic.ic-linkedin-w {
  background-image: url(./assets/images/wb_ic_linkedin-w.svg);
}

.ic.ic-linkedin-b {
  background-image: url(./assets/images/wb_ic_linkedin-b.svg);
}

.ic.ic-linkedin {
  background-image: url(./assets/images/wb_ic_linkedin.svg);
}

.ic.ic-twitter-w {
  background-image: url(./assets/images/wb_ic_twitter-w.svg);
}

.ic.ic-twitter-b {
  background-image: url(./assets/images/wb_ic_twitter-b.svg);
}

.ic.ic-twitter {
  background-image: url(./assets/images/wb_ic_twitter.svg);
}

.ic.ic-facebook-w {
  background-image: url(./assets/images/wb_ic_facebook-w.svg);
}

.ic.ic-facebook-b {
  background-image: url(./assets/images/wb_ic_facebook-b.svg);
}

.ic.ic-facebook {
  background-image: url(./assets/images/wb_ic_facebook.svg);
}

.ic.ic-multi-arrow {
  background-image: url(./assets/images/wb_ic_multi-arrow.svg);
}

[data-clipboard-text] {
  position: relative;
}

[data-clipboard-text] .valid {
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #343434;
  top: 4px;
  left: 100%;
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease-in;
}

[data-clipboard-text][active] .valid {
  opacity: 1;
}

.btn.round {
  height: 36px;
  border-radius: 18px;
  font-size: 16px;
}

.wrapper {
  width: 1584px;
  padding: 0 24px;
  max-width: 100%;
  margin: 0 auto;
}

.wrapper.mini {
  width: 1024px;
}

.wrapper.small {
  width: 1302px;
}

.wrapper.large {
  width: 1780px;
}

.wrapper.full {
  width: 1920px;
  padding: 0;
}

main {
  min-height: 100vh;
}

#app[page="sitemap"] main {
  min-height: 0;
}

.page {
  padding-top: 152px;
  opacity: 0;
  transition: opacity .2s ease-in;
}

.page[loaded] {
  opacity: 1;
}

.page-header {
  margin: 120px 0 136px;
}

.page-header .wrapper {
  padding: 0 96px;
}

.page-header .page-title {
  display: flex;
  align-items: center;
  color: #1DACD6;
  font-size: 20px;
  margin-bottom: 24px;
}

.page-header .page-title:before {
  content: '';
  display: block;
  width: 112px;
  height: 21px;
  background: url(./assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-right: 24px;
}

.page-header h5 {
  color: #656662;
  margin: 16px 0 24px;
}

.page-header p {
  max-width: 715px;
  font-size: 18px;
}

.announcement-bar {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background:#1DACD6;
  color: #fff;
  height: 32px;
  text-align: center;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 600;
}

.announcement-bar .ic {
  width: 12px;
  height: 12px;
  margin-left: 8px;
}

.admin-bar {
  display: none;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 32px 0;
  background: #fff;
  z-index: 100;
  transition: transform .16s ease-out;
  transform: translateY(-100%);
  will-change: auto;
}

#app[announcement] header {
  top: 32px;
}

#app[show-nav] header {
  transform: none;
}


header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header nav a {
  display: flex;
  align-items: center;
  margin-right: 42px;
  font-size: 18px;
  font-weight: 600;
  color: #1DACD6;
  white-space: nowrap;
}

header nav .dropdown .dropdown-menu a {
  font-weight: 600;
}

header nav .about-menu {
  display: none;
}

header nav .logo {
  margin-right: 64px;
}

header .socials {
  margin-right: 12px;
  white-space: nowrap;
}

header .logo img {
  display: block;
  width: 160px;
}

header nav,
header .contact-info {
  display: flex;
  align-items: center;
}

header .contact-info a:not(.btn) {
  margin-right: 12px;
  color: #1DACD6;
}

.bottom-note .note {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background: url(./assets/images/wb_team_photo.jpg) no-repeat center/cover;
  border-radius: 16px;
  padding: 56px 40px;
  overflow: hidden;
  margin-top: 64px;
  margin-bottom: -56px;
}

.bottom-note .note > * {
  position: relative;
}

.bottom-note .note:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(10,126,140);
  background: -moz-linear-gradient(225deg, rgba(10,126,140,1) 0%, rgba(10,126,140,0.69) 100%);
  background: -webkit-linear-gradient(225deg, rgba(10,126,140,1) 0%, rgba(10,126,140,0.69) 100%);
  background: linear-gradient(225deg, rgba(10,126,140,1) 0%, rgba(10,126,140,0.69) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a7e8c",endColorstr="#0a7e8c",GradientType=1);
}

.bottom-note .note h4 {
  margin-bottom: 24px;
}

.bottom-note .note .note-content {
  max-width: 766px;
  margin-right: 24px;
}

.bottom-note .note img {
  display: block;
}

footer {
  background-color: #343434;
  color: #fff;
  padding: 92px 24px 48px;
}

footer .logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 174px;
}

footer .nav-section:first-child {
  padding-top: 96px;
}

footer .nav-section.sub-section {
  background: rgba(101, 102, 98, 0.16);
  padding: 24px 32px;
  border-radius: 8px;
  align-items: center;
}

footer .ebn-info a {
  color: #1DACD6;
  text-decoration: underline;
}

footer .partner-logo {
  display: block;
  height: 64px;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 12px;
}

footer .nav-section {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 48px;
  font-size: 14px;
}

footer .nav-section.vertical {
  justify-content: space-between;
}

footer .col {
  margin: 48px 32px 0;
}

footer .col a {
  display: block;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

footer .col a:not(:hover) .icon[rounded] {
  border-color: #D7D3C8;
}

footer .col .socials {
  display: flex;
}

#app[no-touch] footer .social,
#app[no-touch] footer .privacy-policy a,
#app[no-touch] footer .col a:not(.btn) {
  transition: color .2s ease-out;
}

#app[no-touch] footer .social:hover,
#app[no-touch] footer .privacy-policy a:hover,
#app[no-touch] footer .col a:not(.btn):hover {
  color: #1DACD6;
}

footer h5 {
  font-family: 'Inter';
  margin-bottom: 4px;
  font-size: 20px;
}

footer .social {
  margin: 0 5px;
}

footer .wrapper > .privacy-policy {
  display: none;
  text-align: center;
  margin: 24px 0 0;
  font-size: 14px;
}

footer .privacy-policy a {
  padding: 0 24px;
  border-right: 1px solid #656662;
}

footer .privacy-policy a:last-child {
  border-right: none;
}

footer .col a.mobile {
  display: none;
}

footer .nav-section.vertical.dashed {
  margin-top: 12px;
  padding-top: 12px;
  background: url(./assets/images/wb_ic_dashed.svg) repeat-x top left/auto 1px;
}

footer .privacy-policy {
  flex-grow: 1;
  margin: 0 24px 0 40px;
}

footer .nav-footer {
  align-self: flex-end;
}

footer .nav-footer a {
  color: #1DACD6;
  text-decoration: underline;
}

footer .nav-footer br {
  display: none;
}

footer .mobile {
  display: none;
}

.maintenance {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  font-size: 32px;
  line-height: 1.5;
}

.maintenance img {
  display: block;
  width: 280px;
  margin: 0 auto 32px;
}

.overlay,
.search-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.48);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.16s ease-out;
}

.overlay[opened],
#app[search-opened] .search-panel {
  opacity: 1;
  pointer-events: all;
}

#app[search-opened] main,
#app[search-opened] .side-nav {
  filter: blur(5px);
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  overflow: auto;
}

.overlay .dialog {
  position: relative;
  width: 540px;
  max-width: 100%;
  border-radius: 24px;
  background: #fff;
  overflow: hidden;
}

.overlay .dialog .dialog-image {
  display: block;
  width: 100%;
}

.overlay .dialog .dialog-container {
  padding: 24px;
}

.overlay .dialog .dialog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.overlay .dialog .dialog-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.overlay .dialog .dialog-actions .btn .ic {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.search-panel .search-bar {
  position: relative;
  background: #fff;
}

.search-panel .search-bar form {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: center;
}

.search-panel .search-bar > .ic {
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
}

.search-panel .search-bar input {
  width: 400px;
  border: 1px solid #1dacd6;
  border-radius: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.search-panel .search-bar .btn {
  height: 48px;
}

@keyframes bob {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(5px);
  }
}

@keyframes bobrev {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-5px);
  }
}

.search-panel .search-bar button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.search-panel .search-bar .search-input {
  position: relative;
}

.search-panel .search-bar .search-input .ic,
.search-panel .search-bar .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  width: 12px;
  height: 12px;
  margin: auto;
  color: #1dacd6;
}

.search-panel .search-results {
  position: absolute;
  left: 0;
  right: 0;
  top: 64px;
  max-height: calc(100% - 128px);
  background: #fff;
  width: 800px;
  max-width: 100%;
  padding: 24px 48px;
  margin: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.search-panel .search-results h5 {
  margin-bottom: 16px;
}

.search-panel .search-results h6 {
  text-transform: capitalize;
  font-size: 1.2em;
  margin-bottom: 8px;
}

.search-panel .search-results .result {
  margin-bottom: 16px;
}

.search-panel .search-results .results-category {
  margin-bottom: 24px;
}

.search-panel .search-results .result:last-child {
  margin-bottom: 0;
}

.search-panel .search-results .result .result-title {
  color: #1dacd6;
  font-weight: bold;
  margin-bottom: 4px;
}

.search-panel .search-results .result .result-description {
  opacity: 0.77;
  font-size: 0.9em;
}

.search-panel .search-results .no-results {
  text-align: center;
}

[loader] {
  position: relative;
}

[loader]:after,
.loader {
  display: inline-block;
  vertical-align: middle;
  border-top: 2px solid currentColor;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.72s linear infinite;
}

[loader]:before,
[loader]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

[loader]:before {
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
}

[loader]:after {
  margin: auto;
  z-index: 10;
  color: #e28d4f;
  width: 32px;
  height: 32px;
}

.cookie-settings {
  position: fixed;
  background: #fff;
  border-radius: 16px;
  z-index: 100;
  bottom: 16px;
  right: 16px;
  width: 640px;
  max-width: calc(100vw - 32px);
  padding: 24px;
  font-size: 14px;
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.cookie-settings[opened] {
  visibility: visible;
  opacity: 1;
}

.cookie-settings .cookie-title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
}

.cookie-settings a {
  color: #1DACD6;
  text-decoration: underline;
}

.cookie-settings .cookie-box {
  background: rgba(28, 172, 214, 0.08);
  border-radius: 16px;
  padding: 8px 16px;
  margin-top: 16px;
}

.cookie-settings .cookie-box .cookie-box-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 8px;
}

.cookie-settings .cookie-box .cookie-box-action {
  color: #23b123;
}

.cookie-settings .cookie-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.podcast-player {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 320px;
  z-index: 10;
  border-radius: 16px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  overflow: hidden;
}

.podcast-player[minimized] {
  width: 140px;
}

.podcast-player .podcast-thumbnail {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(./assets/images/wb_bg_podcast-episode.jpg);
}

.podcast-player[minimized] .podcast-thumbnail {
  height: 140px;
}

.podcast-player .podcast-thumbnail:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.72);
}

.podcast-player .podcast-thumbnail > * {
  position: relative;
}

.podcast-player .podcast-thumbnail .ic-minimize,
.podcast-player .podcast-thumbnail .ic-maximize,
.podcast-player .podcast-thumbnail .ic-close-w {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
}

.podcast-player .podcast-thumbnail .ic-minimize,
.podcast-player .podcast-thumbnail .ic-maximize {
  right: 38px;
  top: 8px;
  width: 20px;
  height: 20px;
}

.podcast-player .podcast-thumbnail .ic-play-btn,
.podcast-player .podcast-thumbnail .ic-pause {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

.podcast-player .podcast-thumbnail button:disabled {
  opacity: 0.5;
  cursor: default;
}

.podcast-player .podcast-thumbnail .progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 6px;
  background: #e6e6e6;
  cursor: pointer;
}

.podcast-player .podcast-thumbnail .progress .bar {
  height: 100%;
  background: #1dacd6;
}

.podcast-player .podcast-title {
  font-weight: bold;
  padding: 12px 16px;
  border-top: 0;
  background: #fff;
}

.podcast-player[minimized] .podcast-title {
  display: none;
}

.swiper-container {
  padding-bottom: 72px;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border: 1px solid rgb(29, 172, 214);
  border-radius: 50%;
  background: none;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: rgb(29, 172, 214);
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease-in;
  z-index: 9999;
  padding: 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#app[nav-opened] .mobile-nav {
  opacity: 1;
  pointer-events: all;
}

.mobile-nav .nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.mobile-nav .nav-header a {
  color: #1DACD6;
}

.mobile-nav .nav-header img {
  display: block;
  width: 120px;
}

.mobile-nav .nav-links a {
  display: block;
  text-align: center;
  color: #1DACD6;
  font-weight: 600;
  font-size: 18px;
  margin: 24px 0;
}

.mobile-nav .socials {
  text-align: center;
  margin-top: 32px;
}

.mobile-nav .socials a {
  margin: 0 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1536px) {
  header nav a,
  header nav .dropdown .dropdown-menu a {
    font-size: 16px;
  }
  header .logo img {
    width: 110px;
  }
}

@media screen and (max-width: 1440px) {
  header nav .logo,
  header nav a {
    margin-right: 32px;
  }
  header .contact-info a:not(.btn) {
    margin-right: 0;
  }
  header .socials {
    margin-right: 24px;
  }
}

@media screen and (max-width: 1280px) {
  header nav a,
  header nav .dropdown .dropdown-menu a {
    font-size: 14px;
  }
  header .contact-info a:not(.btn) {
    margin: 0;
  }
  header nav .logo, header nav a {
    margin-right: 24px;
  }
}

@media screen and (max-width: 1520px), screen and (max-height: 820px) {
  h3 {
    font-size: 60px;
  }
}

@media screen and (max-width: 1180px) {
  header .contact-info .btn,
  header nav .about-link {
    display: none;
  }
  header nav .about-menu {
    display: block;
  }
  header .socials {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .search-panel .search-bar input {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    font-size: 14px;
    padding: 0 16px;
  }
  .search-panel .search-results {
    top: 56px;
    bottom: 0;
    max-height: none;
    padding: 24px;
  }
  .search-panel .search-results .result .result-description {
    font-size: 12px;
  }
  .search-panel .search-bar {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    z-index: 2;
  }
  footer .col {
    width: 50%;
    text-align: center;
    margin: 0;
  }
  footer .nav-section:first-child {
    padding-top: 180px;
  }
  footer .col .socials {
    justify-content: center;
  }
  footer .nav-section .privacy-policy {
    display: none;
  }
  footer .wrapper > .privacy-policy {
    display: block;
  }
  footer .col:first-child {
    display: none;
  }
  footer .mobile,
  footer .col a.mobile {
    display: block;
  }
  footer .nav-section:first-child {
    width: 420px;
    max-width: 100%;
    flex-wrap: wrap;
    margin: 48px auto 0;
  }
  .page-header {
    margin-top: 48px;
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 720px) {
  footer .nav-section.sub-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  footer .nav-section.sub-section .btn {
    margin-top: 16px;
  }
}

@media screen and (max-width: 670px) {
  .btn {
    padding: 0 16px;
    height: 48px;
    font-size: 16px;
  }
  h3 {
    font-size: 48px;
  }
  h4 {
    font-size: 24px;
  }
  .search-panel .search-bar form {
    justify-content: flex-start;
    padding-left: 24px;
    max-width: calc(100% - 80px);
  }
  .search-panel .search-bar .search-input {
    flex-grow: 1;
  }
  .search-panel .search-bar input {
    width: 100%;
  }
  .page-header .wrapper {
    padding: 0 32px;
  }
  footer .nav-footer br {
    display: block;
  }
  .cookie-settings {
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    max-width: none;
    overflow: auto;
    max-height: 70%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    box-shadow: 8px -16px 24px rgba(0,0,0,.24);
  }
  footer .nav-section.vertical:not(.sub-section) {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
  footer .nav-footer {
    align-self: center;
  }
  footer .partner-logo {
    margin: 0 auto 12px;
  }
  footer .wrapper > .privacy-policy {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  footer .privacy-policy a {
    padding: 0 12px;
    white-space: nowrap;
  }
  .announcement-bar {
    height: 48px;
    padding: 0 16px;
  }
  #app[announcement] {
    padding-top: 48px;
  }
  #app[announcement] header {
    top: 48px;
  }
  .overlay {
    padding: 16px;
  }
}

@media screen and (max-width: 540px) {
  .bottom-note .note img {
    display: none;
  }
  .page-header .page-title {
    font-size: 16px;
  }
  .wrapper.mini {
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  h3 {
    font-size: 40px;
  }
  .btn {
    min-width: 0;
  }
  .bottom-note .note {
    padding: 32px;
  }
  .bottom-note .note .note-content {
    margin: 0;
  }
  header .contact-info .socials {
    display: none;
  }
  .cookie-settings {
    padding: 16px;
  }
}
</style>
