<template>
  <div :class="`icon ${size}`" :rounded="rounded" v-if="icon">
		<i :class="`${type} fa-${icon}`"></i>
	</div>
</template>

<script>
export default {
	name: "Icon",
	computed: {
		sizeValue() {
			switch (this.size) {
				case 'large':
					return 64;
				case 'small':
					return 32;
				case 'default':
				default:
					return 42;
			}
		},
	},
	props: {
		icon: {
			type: String,
		},
		type: {
			type: String,
			default: 'fab',
		},
		size: {
			type: String,
			default: 'default',
		},
		rounded: {
			type: Boolean,
		},
	},
};
</script>

<style scoped>
.icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 42px;
	height: 42px;
}

.icon[rounded] {
	border: 1px solid;
	border-radius: 50%;
}

.icon.large {
	width: 64px;
	height: 64px;
}

.icon.small {
	width: 32px;
	height: 32px;
}

.icon i {
	font-size: 21px;
}

.icon.large i {
	font-size: 32px;
}

.icon.small i {
	font-size: 16px;
}
</style>
