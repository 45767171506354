import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => {
        return { y: 0 };
    },
    routes: [
        {
            path: '/',
            component: () => import('../components/Home.vue')
        },
        {
            path: '/events',
            component: () => import('../components/Events.vue')
        },
        {
            name: 'event',
            path: '/events/:slug',
            component: () => import('../components/TemplatePost.vue')
        },
        {
            path: '/events/:slug/:title',
            component: () => import('../components/TemplatePost.vue')
        },
        {
            path: '/podcast',
            component: () => import('../components/Podcast.vue')
        },
        {
            path: '/news',
            component: () => import('../components/News.vue')
        },
        {
            name: 'news-post',
            path: '/news/:slug',
            component: () => import('../components/TemplatePost.vue')
        },
        {
            path: '/regional-coworking-hubs',
            component: () => import('../components/Hubs.vue')
        },
        {
            path: '/initiatives',
            component: () => import('../components/Initiatives.vue')
        },
        {
            path: '/initiatives/:slug',
            component: () => import('../components/TemplatePost.vue')
        },
        {
            name: 'incubation',
            path: '/incubation',
            component: () => import('../components/Incubation.vue')
        },
        {
            path: '/incubation/:slug',
            component: () => import('../components/TemplatePost.vue')
        },
        {
            path: '/bic-network',
            component: () => import('../components/Network.vue')
        },
        {
            path: '/sitemap',
            component: () => import('../components/Sitemap.vue')
        },
        {
            path: '/partners-network',
            component: () => import('../components/Partners.vue')
        },
        {
            path: '/contact',
            component: () => import('../components/Contact.vue')
        },
        {
            path: '/regional-offices',
            component: () => import('../components/Incubation.vue')
        },
        {
            path: '/about-us',
            component: () => import('../components/Team.vue')
        },
        {
            path: '/our-team',
            component: () => import('../components/Team.vue')
        },
        {
            path: '/board-of-directors',
            component: () => import('../components/Directors.vue')
        },
        {
            path: '/case-studies',
            component: () => import('../components/CaseStudies.vue')
        },
        {
            path: '/case-studies/:slug',
            component: () => import('../components/CaseStudy.vue')
        },
        {
            path: '/what-we-do',
            component: () => import('../components/WhatWeDo.vue')
        },
        {
            path: '/what-we-do/:slug',
            component: () => import('../components/WhatWeDo.vue')
        },
        {
            path: '/article/:slug',
            component: () => import('../components/TemplatePost.vue')
        },
        {
            path: '/:slug',
            component: () => import('../components/TemplatePage.vue')
        },
    ],
})