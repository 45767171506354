<template>
  <section class="contact-form">
    <div class="wrapper small">
      <div class="form">
        <h3>Let's get in touch</h3>
        <form @submit.prevent="submitForm">
          <div class="field">
            <label>Your name</label>
            <input
              type="text"
              maxlength="255"
              v-model="contactForm.name"
              required
            />
          </div>
          <div class="field">
            <label>Your email</label>
            <input
              type="email"
              maxlength="255"
              v-model="contactForm.email"
              required
            />
          </div>
          <div class="field" v-if="ways && ways.length">
            <label>How did you find us?</label>
            <select v-model="contactForm.way" required>
              <option value=""></option>
              <option :value="way" v-for="(way, index) in ways" :key="`way-${index}`">
                {{way}}
              </option>
            </select>
          </div>
          <div class="field">
            <label>Your message</label>
            <textarea
              v-model="contactForm.message"
              maxlength="1500"
              required
            ></textarea>
          </div>
          <div class="field" v-if="error">
            <div class="error">{{error}}</div>
          </div>
          <div class="field" v-else-if="message">
            <div class="message">{{message}}</div>
          </div>
          <div class="buttons">
            <button class="btn" :disabled="loading">Send message</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "ContactForm",
  computed: {
    ...mapGetters({
      settings: 'getSettings',
      isMobile: 'getMobile',
    }),
    sendContact() {
      return this.$store.state.firebase.functions().httpsCallable('sendContact');
    },
  },
  props: {
    ways: Array,
  },
  data() {
    return {
      contactForm: {
        name: "",
        email: "",
        message: "",
        way: "",
      },
      loading: false,
      error: '',
      message: '',
    };
  },
  methods: {
    submitForm() {
      if (this.loading) return;
      this.loading = true;
      this.error = "";
      this.message = "";
      this.sendContact(this.contactForm)
        .then((res) => {
          if (!res.data) {
            return this.error = "We could not process your request. Please try again.";
          }
          if (res.data.error) {
            return this.error = res.data.error.message;
          }
          this.contactForm = {
            name: "",
            email: "",
            message: "",
            way: "",
          };
          this.message = "Message sent!";
          this.loading = false;
          if (typeof window.ga === 'function') {
            window.ga('send', 'event', 'Contact Form', 'click', 'Send');
          }
        })
        .catch(() => {
          this.error = "An unknown error occurred. Please try again.";
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.contact-form {
  padding: 96px 0;
  background: url(../assets/images/wb_bg_contact-form.png) no-repeat
    center/cover;
}

.contact-form h3 {
  position: relative;
  display: inline-block;
  margin-bottom: 42px;
}

.contact-form h3:after {
  content: "";
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  background: url(../assets/images/wb_ic_westbic-b.svg) no-repeat center/contain;
  top: -0.8em;
  right: -1.3em;
  transform: rotateZ(180deg);
  opacity: 0.46;
}

.contact-form .form {
  border-radius: 8px;
  box-shadow: 0 12px 48px rgba(101, 102, 98, 0.12);
  background: #fff url(../assets/images/wb_contact-mail.svg) no-repeat 85% 72%/35%
    auto;
  padding: 152px 178px 72px;
}

.contact-form .form form {
  width: 50%;
  min-width: 420px;
}

@media screen and (max-width: 1520px), screen and (max-height: 820px) {
  .contact-form .form {
    padding: 96px 96px 72px;
  }
}

@media screen and (max-width: 800px) {
  h3 {
    font-size: 48px;
    margin-bottom: 24px;
  }
  .contact-form .form {
    padding: 72px 48px;
  }
}

@media screen and (max-width: 640px) {
  h3 {
    font-size: 40px;
  }
  .contact-form .form form {
    min-width: 0;
    width: auto;
  }
  .contact-form {
    padding: 64px 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 540px) {
  h3 {
    font-size: 32px;
  }
  .contact-form .form p {
    font-size: 18px;
  }
  .contact-form .form {
    padding: 64px 24px 48px;
  }
}
</style>
