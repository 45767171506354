import Vue from 'vue'
import VueAsyncComputed from 'vue-async-computed'
// import VueAnime from 'vue-animejs'
import { store } from './store/store'
import { router } from './router/router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import CarbonBadge from 'vue-carbonbadge'

import 'swiper/css/swiper.min.css'

import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VueAsyncComputed)
Vue.use(VueAwesomeSwiper)
Vue.use(CarbonBadge)
// Vue.use(VueAnime)

const textonly = (el, binding) => {
  const div = document.createElement('div')
  div.innerHTML = binding.value
  const p = div.querySelector('p')
  if(p) el.innerText = p.innerText.length > 140 ? p.innerText.substr(0, 180) + '...' : p.innerText
  else el.innerText = ''
  div.remove()
}

const link = (el, binding) => {
  if(binding.value) {
    el.href = /^http(|s):\/\//.test(binding.value) ? binding.value : `http://${binding.value}`
  }
}

Vue.directive('textonly', {
  inserted: textonly,
  update: textonly
})

Vue.directive('link', {
  inserted: link,
  update: link
})

window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame    ||
  function( callback ){
    window.setTimeout(callback, 1000 / 60);
  }
})()

Vue.mixin({
  methods: {
    scrollToY(scrollTargetY = 0, element = null, cb, speed = 2000, easing = 'easeOutSine') {
      const scrollY = element ? element.scrollTop : (window.scrollY || document.documentElement.scrollTop)
      let currentTime = 0
      const time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8))
      const easingEquations = {
        easeOutSine: (pos) => {
          return Math.sin(pos * (Math.PI / 2))
        },
        easeInOutSine: (pos) => {
          return (-0.5 * (Math.cos(Math.PI * pos) - 1))
        },
        easeInOutQuint: (pos) => {
          if ((pos /= 0.5) < 1) {
            return 0.5 * Math.pow(pos, 5)
          }
          return 0.5 * (Math.pow((pos - 2), 5) + 2)
        }
      }
      const tick = () => {
        currentTime += 1 / 60
        const p = currentTime / time
        const t = easingEquations[easing](p)
        if (p < 1) {
          window.requestAnimFrame(tick)
          if(element) element.scrollTop = scrollY + ((scrollTargetY - scrollY) * t)
          else window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
        } else {
          if(element) element.scrollTop = scrollTargetY
          else window.scrollTo(0, scrollTargetY)
          if(typeof cb === 'function') cb()
        }
      }
      tick()
    },
  }
})

router.beforeEach((to, from, next) => {
  if(typeof window.ga === 'function') {
    window.ga('set', 'page', to.path)
    window.ga('send', 'pageview')
  }
  next()
})

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
